import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { provideIsLoadingProp, } from "@/utils/context/LoadingContext";
import { THEME } from "@core/theme";
const { skeletonBase } = THEME;
const StyledContractPartnerLabel = styled.div.attrs(provideIsLoadingProp) `
  display: flex;
  flex-direction: row;
  height: auto;
  margin-bottom: auto;
  align-items: center;
  font-size: 0.875rem;

  ${({ isLoading }) => isLoading && skeletonBase};

  @media (max-width: 760px) {
    margin-bottom: 1rem;
  }
`;
const ContractPartnerName = styled.span `
  font-weight: bold;
  margin: auto 0.25rem;
`;
const Icon = styled.i `
  cursor: pointer;
`;
export const ContractPartnerLabel = ({ contractPartner = "", onClick, }) => (_jsxs(StyledContractPartnerLabel, { children: ["Vertragspartner ist", " ", _jsx(ContractPartnerName, { "data-qa": "contractPartner", children: contractPartner }), " ", _jsx(Icon, { onClick: (e) => {
                e.stopPropagation();
                onClick();
            }, className: "icon-MM_iconfont_Info", role: "dialog", "aria-modal": "true", "aria-label": "Details zum Vertragspartner" })] }));
