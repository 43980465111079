import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { noop } from "lodash";
import {
  position,
  compose,
  color as themeColor,
  typography,
  layout,
  variant,
  space as composeSpace,
} from "styled-system";

import LoadingCircle from "../LoadingCircle";

const StyledButton = styled("button")(
  {
    position: "relative",
    height: "56px",
    borderRadius: ({ theme: { button } }) => button.borderRadius,
    color: ({ disabled, theme: { colors } }) =>
      disabled ? colors.grey[2] : colors.white,
    borderWidth: "2px",
    borderStyle: "solid",
    fontFamily: ({ theme }) => theme.fonts.text,
    fontSize: "18px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.33",
    letterSpacing: "0.4px",
    textAlign: "center",
    userSelect: "none",
    cursor: ({ disabled }) => (disabled ? "not-allowed" : "pointer"),
    outline: "none",
    padding: "0 24px",
    transition: "background-color 0.2s ease",
    "> :nth-child(n + 2)": {
      marginLeft: "4px",
    },
    "&.small": {
      height: "48px",
      fontSize: "16px",
    },
    "&.full-width": {
      width: "100%",
    },
    "&:hover, &:active": {
      boxShadow:
        "0 2px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.15)",
    },
  },
  variant({
    prop: "color",
    variants: {
      prominent: {
        backgroundColor: ({ colors }) => colors.primary[0],
        borderColor: ({ colors }) => colors.primary[0],
        "&:focus": {
          borderColor: ({ colors }) => colors.grey[0],
        },
        "&:hover, &:active": {
          backgroundColor: ({ colors }) => colors.primary[1],
          borderColor: ({ colors }) => colors.primary[1],
        },
      },
      "prominent-disabled": {
        backgroundColor: ({ colors }) => colors.grey[3],
        borderColor: ({ colors }) => colors.grey[3],
      },
      primary: {
        backgroundColor: ({ colors }) => colors.grey[6],
        borderColor: ({ colors }) => colors.grey[6],
        "&:hover, &:active": {
          backgroundColor: ({ colors }) => colors.grey[5],
          borderColor: ({ colors }) => colors.grey[5],
        },
      },
      "primary-disabled": {
        backgroundColor: ({ colors }) => colors.grey[3],
        borderColor: ({ colors }) => colors.grey[3],
        "&:hover, &:active": {
          backgroundColor: ({ colors }) => colors.grey[3],
          borderColor: ({ colors }) => colors.grey[3],
        },
      },
      secondary: {
        backgroundColor: ({ colors }) => colors.white,
        color: ({ colors }) => colors.grey[0],
        borderColor: ({ colors }) => colors.grey[0],
      },
      "secondary-disabled": {
        backgroundColor: ({ colors }) => colors.white,
        color: ({ colors }) => colors.grey[3],
        borderColor: ({ colors }) => colors.grey[3],
      },
      ghost: {
        fontWeight: "normal",
        lineHeight: "1.5",
        color: ({ colors }) => colors.grey[0],
        backgroundColor: ({ colors }) => colors.white,
        border: "none",
        "&:hover, &:active": {
          boxShadow: "none",
          textDecoration: "underline",
        },
      },
      "ghost-disabled": {
        fontWeight: "normal",
        lineHeight: "1.5",
        color: ({ colors }) => colors.grey[3],
        backgroundColor: ({ colors }) => colors.white,
        border: "none",
        "&:hover, &:active": {
          boxShadow: "none",
          textDecoration: "underline",
        },
      },
    },
  }),
  variant({
    prop: "size",
    variants: {
      fullWidth: {
        width: "100%",
      },
      small: {
        height: "48px",
        fontSize: "16px",
      },
      circle: {
        borderRadius: ({ radii }) => radii.circle,
        width: ({ space }) => `${space.xxl + space.l}px`,
        height: ({ space }) => `${space.xxl + space.l}px`,
        padding: 0,
        "> i": {
          color: ({ colors }) => colors.primary[0],
          backgroundColor: ({ colors }) => colors.white,
          padding: ({ space }) => `${space.xxs}px`,
          width: ({ space }) => `${space.xxs}px`,
          height: ({ space }) => `${space.xxs}px`,
          borderRadius: ({ space }) => `${space.xxs}px`,
          fontSize: ({ space }) => `${space.s}px`,
          top: ({ space }) => `-${space.xxs / 2}px`,
          position: "relative",
        },
      },
    },
  }),
  variant({
    prop: "loading",
    variants: {
      true: {
        color: "transparent",
      },
    },
  }),
  compose(themeColor, typography, layout, composeSpace, position)
);

const Button = forwardRef(
  (
    {
      className,
      children,
      onClick,
      color,
      variant: btnVariant,
      disabled,
      loading,
      size,
      qaName,
      ...props
    },
    ref
  ) => {
    const clickHandler = (ev) => {
      if (!disabled && !loading && onClick) {
        onClick(ev);
      }
    };
    return (
      <StyledButton
        className={className}
        onClick={clickHandler}
        color={`${color}${disabled ? "-disabled" : ""}`}
        disabled={disabled}
        variant={btnVariant}
        size={size}
        ref={ref}
        loading={loading.toString()}
        data-qa={qaName}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      >
        {loading && (
          <LoadingCircle
            className={
              ["secondary", "ghost"].includes(color)
                ? `${className} ${color}`
                : className
            }
          />
        )}
        {children}
      </StyledButton>
    );
  }
);

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.oneOf(["prominent", "primary", "secondary", "ghost"]),
  variant: PropTypes.oneOf(["default", "circle"]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  /**
   * Size of the button
   */
  size: PropTypes.oneOf(["default", "fullWidth", "small", "circle"]),
  qaName: PropTypes.string,
};

Button.defaultProps = {
  className: "",
  onClick: noop,
  color: "prominent",
  variant: "default",
  loading: false,
  disabled: false,
  size: "default",
};

Button.displayName = "Button";

export default Button;
