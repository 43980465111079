import React from "react";
import Select from "react-select";
import styled from "styled-components";
import PropTypes from "prop-types";
import find from "lodash/find";

import InputLabel from "../Input/Label";

const StyledSelect = styled(Select)`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  .select__control,
  .select__control:hover {
    min-height: 10px;
    border: none;
    box-shadow: none;
    border-bottom: 2px ${({ theme }) => theme.colors.grey[3]} solid;
    border-radius: 0;
  }
  .select__menu {
    border-radius: 0;
    margin-top: 0;
    z-index: 100;
  }
  .select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }
  .select__value-container {
    padding: 0;
  }
  .select__single-value > div {
    margin: 0.3em 0;
  }
  .select__indicator-separator {
    display: none;
  }
  .select__dropdown-indicator svg {
    fill: ${({ theme }) => theme.colors.grey[5]};
  }
  .select__option {
    color: ${({ theme }) => theme.colors.grey[0]};
    background: ${({ theme }) => theme.colors.white};
    padding-top: ${({ theme }) => theme.padding[2]};
    padding-bottom: ${({ theme }) => theme.padding[2]};
    height: ${({ theme }) => theme.space.xxl}px;
    display: flex;
    align-items: center;
  }
  .select__option--is-focused,
  .select__option:hover {
    background-color: ${({ theme }) => theme.colors.grey[1]};
  }
  .select__option--is-selected div {
    color: ${({ theme }) => theme.colors.primary[0]}!important;
  }
  .select__option--is-disabled {
    text-decoration: line-through;
    #colorDot {
      background-color: gray;
    }
    label {
      color: gray;
    }
  }
`;

const getSelectedOption = (options, selected) =>
  find(options, { value: selected });
export const Dropdown = ({ options, selected, onChange, label }) => (
  <>
    <InputLabel size="small" layout="relative">
      {label}
    </InputLabel>
    <StyledSelect
      value={getSelectedOption(options, selected)}
      // TODO refactor me to only pass option as first argument
      onChange={(option) => onChange(option.value, option)}
      isSearchable={false}
      classNamePrefix="select"
      options={options}
    />
  </>
);

Dropdown.defaultProps = {
  options: [],
  selected: 0,
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string.isRequired,
      selected: PropTypes.bool,
    })
  ),
  label: PropTypes.node.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
};

export default Dropdown;
