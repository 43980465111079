import React, { useMemo } from "react";
import styled from "styled-components";

import { Dropdown } from "@mm/ui";
import { useViewport } from "@core/theme";
import { sortBy, uniqBy } from "lodash";

import {
  useFilterProvider,
  updateTariffs,
} from "features/Filter/FilterProvider";

import {
  useOfferConfigurator,
  SET_ACTIVE_VARIANT,
  SET_ACTIVE_TARIFF,
} from "features/Offer";
import { findVariantById } from "utils/variantUtils";
import dataLayer from "helper/dataLayer";
import SelectableList from "components/molecules/SelectableList";

const selectify = (variants, activeVariantId, hex) => {
  let retVal = [];
  variants.forEach((variant) => {
    if (hex === variant.variant.color.hex) {
      const filteredVariantsByStorage = variants.filter(
        (item) => item.variant.storage === variant.variant.storage
      );
      const filteredStockItems = filteredVariantsByStorage.map(
        (item) => item.variant.stock
      );
      const stockFromAllVariantsPerStorageSize = filteredStockItems.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );
      const result = {
        disabled: stockFromAllVariantsPerStorageSize <= 0,
        label: `${variant.variant.storage}GB`,
        selected: variant.variant.ebootisId === activeVariantId,
        storage: variant.variant.storage,
        value: variant.variant.ebootisId,
      };
      retVal.push(result);
    }
  });
  retVal = uniqBy(sortBy(retVal, ["storage"]), "label");
  return retVal;
};

const MarginBottom = styled.div`
  margin-bottom: 0.5em;
`;

const StoragePicker = () => {
  const viewport = useViewport();
  const { dispatch: filterDispatch } = useFilterProvider();
  const {
    state: { extendedVariants, activeVariantId },
    dispatch,
  } = useOfferConfigurator();

  const handleOnClick = (variantId, item) => {
    const getTariffFromStorageVariant = findVariantById(
      extendedVariants,
      variantId
    );

    dispatch({
      type: SET_ACTIVE_VARIANT,
      payload: {
        extendedVariants,
        activeVariantId: variantId,
      },
    });

    dispatch({
      type: SET_ACTIVE_TARIFF,
      payload: {
        calculateExtendTariff: getTariffFromStorageVariant,
      },
    });

    dataLayer({
      eventCategory: "Produktfilter",
      eventAction: "select",
      eventLabel: `Speicherkapazität:${item.label}`,
    });

    updateTariffs(filterDispatch, item);
  };

  const items = useMemo(() => {
    const {
      variant: {
        color: { hex },
      },
    } = findVariantById(extendedVariants, activeVariantId);

    return selectify(extendedVariants, activeVariantId, hex);
  });

  const selected = React.useMemo(
    () => items.find((item) => item.selected),
    [items]
  );

  return viewport.greaterThan.sm ? (
    <>
      <MarginBottom>
        Speicherkapazität: <b>{selected && selected.label}</b>
      </MarginBottom>
      <SelectableList
        dataQa="storageSizes"
        items={items}
        onClick={(item) => {
          handleOnClick(item.value, item);
        }}
      />
    </>
  ) : (
    <Dropdown
      label="Speicherkapazität"
      options={items}
      onChange={handleOnClick}
      selected={activeVariantId}
    />
  );
};

export default StoragePicker;
