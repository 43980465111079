import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Selectable from "components/molecules/Selectable";

const Container = styled.div`
  & > * {
    margin-right: ${({ theme }) => theme.space.s}px;
    margin-bottom: ${({ theme }) => theme.space.s}px;
  }
`;

const SelectableList = ({
  onClick,
  onMouseEnter,
  onMouseLeave,
  items,
  dataQa,
}) => (
  <Container>
    {items.map((item) => (
      <Selectable
        key={item.label}
        disabled={item.disabled}
        selected={item.selected}
        onClick={() => onClick(item)}
        onMouseEnter={() => onMouseEnter(item)}
        onMouseLeave={() => onMouseLeave(item)}
        value={item.value}
        dataQa={dataQa}
      >
        {item.label || item.value}
      </Selectable>
    ))}
  </Container>
);
SelectableList.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onMouseEnter: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onMouseLeave: () => {},
};

SelectableList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      selected: PropTypes.bool,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  dataQa: PropTypes.string,
};

export default SelectableList;
