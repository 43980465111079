import React, { useState } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import styled from "styled-components";

import { ZoomImage } from "components/atoms";
import { Slider, SoldOverlay } from "components/molecules";

import { ThumbnailSlider } from "./ThumbnailSlider";

const ProductSliderContainer = styled("div")`
  position: relative;
`;

const ImageContainer = styled("div")`
  outline: none;
  text-align: center;
  margin-bottom: 25px;
`;

const ZoomedImage = styled(ZoomImage)`
  min-height: 288px;
  height: 100%;

  figure,
  img {
    margin: 0 auto;
    max-height: 288px;
    width: unset;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.lg.min}px) {
    min-height: 440px;

    figure,
    img {
      margin: 0 auto;
      max-height: 440px;
      width: unset;
    }
  }
`;

const EyeCatcherContainer = styled("div")`
  position: absolute;
  left: 0;
  top: ${({ theme: { space } }) => space.xxl}px;
`;

export const ProductSlider = ({
  images,
  navigationType,
  isSoldOut,
  eyeCatcher,
  thumbnailAmount,
  hardware,
}) => {
  const [currentItem, setCurrentItem] = useState(0);
  const useThumbnails = navigationType === "thumbnails" && images.length > 1;
  const SliderWrapper = isSoldOut ? SoldOverlay : React.Fragment;
  const { manufacturer, name } = hardware;

  return (
    <ProductSliderContainer>
      <SliderWrapper>
        <Slider
          sliderSettings={{
            autoPlay: false,
            arrows: false,
            dots: false,
            beforeChange: (_, idx) => setCurrentItem(idx),
            selectedItem: currentItem,
          }}
        >
          {images.map((image) => (
            <ImageContainer key={image}>
              <ZoomedImage
                innerPadding={35}
                img={image}
                alt={manufacturer + " " + name}
              />
            </ImageContainer>
          ))}
        </Slider>
      </SliderWrapper>
      {useThumbnails && images.length && (
        <ThumbnailSlider
          images={images}
          amountPerPage={thumbnailAmount}
          onClickThumbnail={(idx) => setCurrentItem(idx)}
          selectedItem={currentItem}
        />
      )}

      {!!eyeCatcher && <EyeCatcherContainer>{eyeCatcher}</EyeCatcherContainer>}
    </ProductSliderContainer>
  );
};

ProductSlider.propTypes = {
  hardware: PropTypes.object,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  navigationType: PropTypes.oneOf(["thumbnails", "dots"]),
  thumbnailAmount: PropTypes.number,
  sliderConfig: PropTypes.shape({
    onInit: PropTypes.func,
    beforeChange: PropTypes.func,
    afterChange: PropTypes.func,
  }),
  isSoldOut: PropTypes.bool,
  eyeCatcher: PropTypes.node,
};

ProductSlider.defaultProps = {
  hardware: {},
  navigationType: "dots",
  thumbnailAmount: 3,
  sliderConfig: {
    onInit: noop,
    beforeChange: noop,
    afterChange: noop,
  },
  isSoldOut: false,
  eyeCatcher: null,
};

export default ProductSlider;
