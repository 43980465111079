import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button } from "@mm/ui";
import { TariffPriceDisplay } from "@/components/molecules";
import dataLayer from "@/helper/dataLayer";
import { useProductConfiguration } from "@/features/ProductConfiguration";
import { ACTIONS, useInternetProvider } from "@/features/Internet";
const StyledFooter = styled("footer") `
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: auto;
  gap: ${({ theme }) => theme.space.s}px;

  > div:first-of-type {
    flex: 1 0 0;
    text-align: left;
  }

  > div {
    flex: 1 0 0;
    text-align: right;

    > button {
      max-width: 230px;
      width: 100%;
    }
  }

  .priceIntervals {
    font-size: 12px;
    font-weight: 400;
  }

  .pkBonus {
    font-size: 12px;
    font-weight: 600;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
    > div:first-of-type {
      flex: 1 0 0;
    }
  }
`;
const Footer = ({ accessories = [], availabilityChecked, offerGroupUrl, accessoriesAlternativePrice, tariff, }) => {
    const { pricingIntervals: { intervals }, } = tariff;
    const { state, dispatch } = useInternetProvider();
    const { address } = state ?? { address: undefined };
    const { isLoading, isFetching, refetch: postSelection, } = useProductConfiguration(offerGroupUrl, {
        accessories: accessories.map((hardware) => ({
            hardware: hardware.id,
            variant: hardware.extendedVariants?.[0].variant.ebootisId ?? "",
        })),
        address,
        tariff: tariff.id,
        options: [],
    }, {
        enabled: false,
        refetchOnWindowFocus: false,
    });
    const [isRouting, setIsRouting] = useState(false);
    const navigate = useNavigate();
    return (_jsxs(StyledFooter, { children: [_jsxs("div", { children: [_jsx(TariffPriceDisplay, { tariff: tariff, size: "s", noPostText: true }), intervals && intervals.length > 1 && (_jsxs(_Fragment, { children: [_jsxs("span", { className: "priceIntervals", children: ["ab dem ", intervals[1].startMonth, ". Monat \u20AC ", intervals[1].price] }), _jsx("br", {})] })), accessoriesAlternativePrice >= 1 && (_jsxs("span", { className: "pkBonus", children: ["Pr\u00E4mie ab einmalig ", accessoriesAlternativePrice, "\u20AC"] })), accessoriesAlternativePrice == 0 &&
                        accessories.map((accessory) => {
                            if (!accessory.extendedVariants)
                                return null;
                            return accessory.extendedVariants.map(({ variant }) => {
                                if (variant.price >= 1)
                                    return null;
                                return (_jsxs("span", { className: "pkBonus", children: ["Pr\u00E4mie einmalig ", variant?.price, "\u20AC"] }, variant?.price));
                            });
                        })] }), _jsx("div", { children: availabilityChecked ? (_jsx(Button, { disabled: isLoading || isFetching || isRouting, onClick: () => {
                        setIsRouting(true);
                        dataLayer({
                            eventCategory: "Internet:Teaser",
                            eventAction: "AddToCart",
                            eventLabel: "Internet:Action:AddToCart:Teaser",
                        });
                        dispatch({
                            type: ACTIONS.SET_SHOW_LOADING_OVERLAY,
                        });
                        postSelection().then((data) => {
                            if (data.data?.url) {
                                dispatch({
                                    type: ACTIONS.SET_SHOW_LOADING_OVERLAY,
                                    payload: false,
                                });
                                navigate(`/dsl-und-internet/pks/${data.data.url}`);
                                setIsRouting(false);
                            }
                        });
                    }, qaName: `${tariff.serviceProvider}-${tariff.connectionType}-${tariff.downloadSpeed}-${tariff.id}`, children: "Zum Tarif" })) : (_jsx(Button, { disabled: isLoading || isFetching || isRouting, onClick: () => {
                        const fr = document.querySelector(".internet-hero__form");
                        const el = document.getElementById("zipcode");
                        if (el && fr) {
                            fr.scrollIntoView({ behavior: "smooth" });
                            el.focus({ preventScroll: true });
                        }
                    }, qaName: `${tariff.serviceProvider}-${tariff.connectionType}-${tariff.downloadSpeed}-${tariff.id}`, children: "Verf\u00FCgbarkeit pr\u00FCfen" })) })] }));
};
export default Footer;
