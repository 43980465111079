import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ACTIONS as ModalActions, useModal, } from "@/utils/context/ModalContext";
import { Headline } from "@mm/ui";
import { DetailsAccordion, Modal } from "@/components/atoms";
export const TariffDetailsModal = ({ details, title, }) => {
    const { dispatch } = useModal();
    return (_jsxs(Modal, { onClose: () => dispatch({
            type: ModalActions.CLEAR_MODAL,
        }), children: [_jsx(Headline, { variant: "h2", children: title }), _jsx(DetailsAccordion, { details: details, autoWidth: true }), details?.addresses && (_jsxs(_Fragment, { children: [_jsx("div", { className: "msd-manufacturer-addresses-headline", children: _jsx("span", { children: _jsx("b", { children: "Hersteller" }) }) }), _jsx("div", { className: "msd-manufacturer-addresses-wrapper", children: details?.addresses?.map((address) => (_jsxs("div", { className: "address", children: [_jsx("div", { children: address.companyName }), _jsx("div", { children: _jsx("a", { href: `mailto:${address.mail}`, target: "_blank", rel: "noreferrer", children: address.mail }) }), _jsx("div", { children: _jsx("a", { href: address.website, target: "_blank", rel: "noreferrer", children: address.website }) }), _jsxs("div", { children: [_jsx("span", { children: address.street }), " ", _jsx("span", { children: address.streetNumber })] }), _jsxs("div", { children: [_jsx("span", { children: address.zip }), " ", _jsx("span", { children: address.city })] }), _jsx("div", { children: _jsx("span", { children: address.country }) })] }, address.companyName))) })] }))] }));
};
