import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { noop } from "lodash";
import css from "@styled-system/css";
import { Box, Modal } from "@mm/ui";

export const OpenerButton = styled.div`
  cursor: pointer;
  display: inline;
`;

class GeneralModalShell extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: props.isOpen || false,
      isControlled: props.isOpen !== undefined,
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.isOpen !== undefined &&
      nextProps.isOpen !== prevState.modalIsOpen
    ) {
      return {
        modalIsOpen: nextProps.isOpen,
      };
    }
    return null;
  }

  handleKeyDown(ev) {
    ev.preventDefault();
    if (ev.keyCode === 13) {
      this.toggleModal(ev);
    }
  }

  toggleModal(e) {
    e.preventDefault();
    const { trackingObj = false } = this.props;
    const { modalIsOpen, isControlled } = this.state;

    if (!modalIsOpen && trackingObj && window.dataLayer) {
      window.dataLayer.push(trackingObj);
    }

    if (isControlled) {
      this.props.onToggle(!modalIsOpen);
    } else {
      this.setState({ modalIsOpen: !modalIsOpen });
    }
  }

  render() {
    const {
      children,
      opener,
      afterOpen = undefined,
      size,
      dynamicHeight,
      closable,
      maxHeightOffSet,
    } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <Box
        {...this.props}
        height={dynamicHeight ? "100%" : "auto"}
        css={css({
          display: "inline-block",
        })}
      >
        <OpenerButton
          onClick={(e) => {
            e.stopPropagation();
            this.toggleModal(e);
          }}
        >
          {opener}
        </OpenerButton>
        {modalIsOpen && (
          <Modal
            dynamicHeight={dynamicHeight}
            isInitialOpen={modalIsOpen}
            onAfterOpen={afterOpen}
            onRequestClose={(e) => {
              if (closable) {
                this.toggleModal(e);
                e.stopPropagation();
              }
            }}
            closable={closable}
            size={size}
            maxHeightOffSet={maxHeightOffSet}
          >
            {children}
          </Modal>
        )}
      </Box>
    );
  }
}

GeneralModalShell.propTypes = {
  afterOpen: PropTypes.func,
  children: PropTypes.node.isRequired,
  opener: PropTypes.node,
  trackingObj: PropTypes.any,
  isOpen: PropTypes.bool,
  onToggle: PropTypes.func,
  size: PropTypes.oneOf(["sm", "md", "lg", "xl", "xxl"]),
  dynamicHeight: PropTypes.bool,
  closable: PropTypes.bool,
  maxHeightOffSet: PropTypes.shape({}),
};

GeneralModalShell.defaultProps = {
  opener: null,
  afterOpen: noop,
  isOpen: undefined,
  onToggle: noop,
  trackingObj: {},
  size: "lg",
  dynamicHeight: false,
  closable: true,
  maxHeightOffSet: {
    md: "64px",
    lg: "128px",
  },
};

export default GeneralModalShell;
