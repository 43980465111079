import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import TickSvg from "@core/svgs/tick.svg";

const StyledDiv = styled.div`
  width: 1.5em;
  height: 1.5em;
  border-radius: 4px;
  cursor: pointer;
  border: solid 1px
    ${({ theme, disabled }) =>
      disabled ? theme.colors.grey[3] : theme.colors.grey[5]};
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme, disabled }) =>
    disabled
      ? `
    cursor: default;
    svg path {
      stroke: ${theme.colors.grey[3]};
    }
  `
      : ""}
`;

const Checkbox = ({ checked, className, disabled, id, qaName }) => (
  <StyledDiv
    checked={checked}
    className={className}
    disabled={disabled}
    id={id}
    data-qa={qaName}
  >
    {checked && <TickSvg width="90%" viewBox="-2 -1 17 11" />}
  </StyledDiv>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  qaName: PropTypes.string,
};

Checkbox.defaultProps = {
  checked: false,
  className: "",
  disabled: false,
  id: "",
};

export default Checkbox;
