import React, { useState } from "react";

import { Box, Headline, Surface, Col, Row } from "@mm/ui";
import { useViewport } from "@core/theme";

import dataLayer from "helper/dataLayer";

import { useOfferConfigurator } from "features/Offer";

import {
  Breadcrumb,
  SmallInlineLink,
  Eyecatchers,
  GeneralModalShell,
  ProductDetailPageTopBanner,
  BorderBox,
} from "components/atoms";

import {
  ColorPicker,
  Loading,
  StoragePicker,
  DeliveryInfoModal,
  HardwareInfo,
  SelectedTariff,
} from "components/molecules";

import {
  ProductSlider,
  SelectedAccessories,
  SelectedPkBonuses,
} from "components/organisms";

import { PriceSection } from "sections";

import useActiveVariant from "sections/HardwareDetailsSection/useActiveVariant";

import { ProductHeadline } from "./ProductHeadline";

/* import styled from "styled-components";
import { breakpoints } from "@core/theme/src/theme/theme";

const PdpBannerWrapper = styled.div`
  .pdpBanner {
    height: auto;
    width: 100%;
  }
`; */

const HardwareDetailsSection = () => {
  const {
    state: {
      rootInfo,
      extendedVariants,
      extendedVariant,
      activeTariff,
      stock,
      activeAccessoryVariantIds,
    },
  } = useOfferConfigurator();
  // create an internal variant for hover changes
  const [internalActiveVariant, setInternalActiveVariant] = useState({});

  const [activeVariantId, activeExtendedVariant] = useActiveVariant(
    internalActiveVariant
  );

  const { is } = useViewport();

  if (!rootInfo || !extendedVariants || !activeExtendedVariant) {
    return <Loading variant="details" gridWrapper marginTop={4} />;
  }

  const { hardware, accessories, breadcrumb } = rootInfo;

  const { eyeCatchers } = activeExtendedVariant;

  const isSoldOut = stock <= 0;

  return (
    <>
      <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
        <Row>
          <Col xs={24} xl={12}>
            {is.desktop && <ProductHeadline variant="h2" hardware={hardware} />}
            {breadcrumb && breadcrumb.length && (
              <Breadcrumb isProductDetailInterface items={breadcrumb} />
            )}
          </Col>
        </Row>
      </Surface>
      <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
        <Row>
          {/* Product Slider */}
          <Col xs={24} md={12} lg={6}>
            <Eyecatchers
              eyecatchers={eyeCatchers}
              mb={25}
              mx={0}
              variant="noMinHeight"
            />
            <ProductSlider
              images={activeExtendedVariant?.variant.images}
              isSoldOut={isSoldOut}
              navigationType="thumbnails"
              hardware={hardware}
            />
          </Col>
          {/* Variant picker */}
          <Col xs={24} md={12} lg={6}>
            {(is.tablet || is.mobile) && (
              <ProductHeadline variant="h3" hardware={hardware} />
            )}
            {extendedVariants?.length > 0 && hardware && (
              <Row mb={5}>
                <Col xs={12} md={24} mb={5}>
                  <ColorPicker
                    accessories={accessories}
                    activeAccessoryVariantIds={activeAccessoryVariantIds}
                    setInternalActiveVariant={setInternalActiveVariant}
                    activeVariantId={activeVariantId}
                  />
                </Col>
                <Col xs={12} md={24}>
                  <StoragePicker
                    setInternalActiveVariant={setInternalActiveVariant}
                    activeVariantId={activeVariantId}
                  />
                </Col>
                {hardware && (
                  <Col xs={24}>
                    <GeneralModalShell
                      opener={
                        <SmallInlineLink
                          href="#"
                          onClick={(evt) => {
                            evt.preventDefault();
                            dataLayer({
                              eventCategory: "Hardwaredetails",
                              eventAction: "click",
                              eventLabel:
                                "Geräte-Details:undefined:undefined:HardwareDetailsSection",
                            });
                          }}
                        >
                          Geräte-Details
                        </SmallInlineLink>
                      }
                    >
                      <Headline variant="h2">Geräte-Details</Headline>
                      <HardwareInfo hardware={hardware} />
                    </GeneralModalShell>
                  </Col>
                )}
              </Row>
            )}
          </Col>
          {/* Tariff, Accessory & Price */}
          <Col xs={24} lg={12}>
            <Row>
              {activeTariff && (
                <Col xs={24} md={12} lg={24}>
                  <Box mb={5}>
                    <SelectedTariff tariff={activeTariff} />
                  </Box>
                </Col>
              )}
              {accessories?.length > 0 && (
                <Col xs={24} md={12} lg={24}>
                  <BorderBox pt={0} px={5} mb={5}>
                    {accessories.map((accessory, index) => {
                      const ebootisId =
                        activeAccessoryVariantIds?.[index].ebootisId ||
                        accessory.extendedVariants[0].variant.ebootisId;
                      if (accessory.pkBonus === true) {
                        return (
                          <SelectedPkBonuses
                            key={accessory.name}
                            ebootisId={ebootisId}
                            accessory={accessory}
                          />
                        );
                      }
                      return (
                        <SelectedAccessories
                          key={accessory.name}
                          index={index}
                          ebootisId={ebootisId}
                          accessory={accessory}
                          activeAccessoryVariantIds={activeAccessoryVariantIds}
                        />
                      );
                    })}
                  </BorderBox>
                </Col>
              )}
              <Col xs={24}>
                {extendedVariant && (
                  <PriceSection
                    sticky
                    priceShippingInfo={
                      <DeliveryInfoModal
                        deliveryPrice={extendedVariant.variant.deliveryPrice}
                        deliverySettings={
                          extendedVariant.variant.deliverySettings
                        }
                      />
                    }
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Surface>
      <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
        <ProductDetailPageTopBanner
          conditions={{
            hardwareVariant: activeVariantId,
            // FIXME hardware?.name is no offergroup
            offergroup: hardware?.name,
            tariffId: activeTariff?._id,
            manufacturer: hardware?.manufacturer,
            provider: activeTariff?.serviceProvider,
          }}
        />
      </Surface>
    </>
  );
};

export default HardwareDetailsSection;
