import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import styled from "styled-components";
import classNames from "classnames";

import PlusBubble from "@core/svgs/plus-bubble.svg";
import CheckBubble from "@core/svgs/checkmark.svg";

import Flex from "../Flex";

import Label from "./Label";

const Wrapper = styled(Flex)`
  position: relative;
  min-width: ${({ value }) => (value ? "20px" : "auto")};
  min-height: 56px;
  border-bottom: 2px solid;
  border-bottom-color: ${({ theme: { colors }, value }) =>
    value ? colors.grey[5] : colors.grey[2]};
  outline: none;

  &:not(.disabled) {
    &:hover,
    &:focus,
    &:active {
      border-bottom-color: ${({ theme }) => theme.colors.grey[5]};
    }

    &:hover,
    &:focus,
    &:focus-within {
      label {
        top: 0;
        font-size: ${({ theme }) => theme.typography.size.s}px;
        line-height: 1;
        margin-top: ${({ theme }) => theme.space.xs}px;
        z-index: 2;
      }
    }
  }

  ${(props) => {
    switch (props.layout) {
      case "error":
        return `
          border-bottom-color: ${props.theme.colors.brand.primary} !important;
        `;
      case "disabled":
        return `
          border-bottom-color: #cdcaca;
        `;
      default:
        return ``;
    }
  }}
`;

export const Input = styled("input")`
  border: 0;
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: ${({ theme }) => theme.typography.size.m}px;
  line-height: 2;
  color: inherit;
  width: 100%;
  min-width: 20px;
  background: transparent;
  outline: none;
`;

const Message = styled("div")`
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: ${({ theme }) => theme.typography.size.s}px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.grey[4]};
  margin-top: ${({ theme }) => theme.space.xs}px;
  margin-bottom: ${({ theme }) => theme.space.xs}px;

  ${(props) => {
    switch (props.layout) {
      case "error":
        return `
          color: ${props.theme.colors.brand.primary};
        `;
      default:
        return ``;
    }
  }}
`;

const PlusIcon = styled(PlusBubble)`
  position: absolute;
  bottom: 8px;
  right: 0;
  transform: rotate(45deg);

  ${(props) => {
    switch (props.layout) {
      case "error":
        return `
          display: block;
        `;
      case "disabled":
        return `
          display: block;
        `;
      default:
        return `
          display: none;
        `;
    }
  }}
`;

const CheckMark = styled(CheckBubble)`
  position: absolute;
  bottom: 8px;
  right: 0;

  ${(props) => {
    switch (props.layout) {
      case "success":
        return `
          display: block;
        `;
      default:
        return `
          display: none;
        `;
    }
  }}
`;

const StyledSuffix = styled("span")`
  line-height: 2;
`;

const InputText = forwardRef(
  (
    {
      value,
      label,
      suffix,
      layout,
      message,
      disabled,
      onBlur,
      onChange,
      onFocus,
      onInput,
      maxLength,
      onKeyPress,
      onKeyDown,
      width,
      id,
      qaName,
      readOnly,
      className,
      autoComplete,
      autoFocus,
    },
    ref
  ) => (
    <div data-tk-cobrowse-hidden="true">
      <Wrapper
        className={classNames(disabled ? "disabled" : "", className)}
        justifyContent="space-between"
        flexWrap="wrap"
        alignContent="flex-end"
        layout={layout}
        value={value}
        width={width}
      >
        <Label
          htmlFor={id}
          text={label}
          size={value && "small"}
          layout={layout}
        />
        <>
          <Input
            id={id}
            data-testid={id}
            value={value}
            disabled={disabled}
            layout={layout}
            onBlur={(evt) => onBlur && onBlur(evt.currentTarget.value)}
            onChange={(evt) => onChange && onChange(evt.currentTarget.value)}
            onFocus={(evt) => onFocus && onFocus(evt.currentTarget.value)}
            onInput={(evt) => onInput && onInput(evt)}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            maxLength={maxLength}
            readOnly={readOnly}
            ref={ref}
            data-qa={qaName}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
          />
          <StyledSuffix>{value && suffix}</StyledSuffix>
          <PlusIcon
            layout={layout}
            width="16"
            height="16"
            viewBox="0 0 25 25"
          />
          <CheckMark layout={layout} width="16" height="16" />
        </>
      </Wrapper>
      {message && <Message layout={layout}>{message}</Message>}
    </div>
  )
);

InputText.defaultProps = {
  suffix: null,
  layout: "normal",
  message: null,
  disabled: false,
  onBlur: noop,
  onFocus: noop,
  onKeyPress: noop,
  maxLength: undefined,
  readOnly: false,
  width: "100%",
  autoComplete: null,
  autoFocus: false,
};

InputText.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  suffix: PropTypes.string,
  message: PropTypes.string,
  layout: PropTypes.oneOf(["error", "success", "disabled", "normal"]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.string,
  width: PropTypes.string,
  qaName: PropTypes.string,
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.boolean,
};

InputText.displayName = "InputText";

export default InputText;
