import React from "react";
import styled from "styled-components";

import { Image } from "@mm/ui";

import { useOfferConfigurator } from "features/Offer";

import { Bulletlist, Price } from "components/atoms";

import Header from "./Header";
import DetailsLinks from "./Details";
import Footer from "./Footer";

const ContentWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${({ theme }) => theme.space.s}px;
  margin-bottom: ${({ theme }) => theme.space.xxlLong}px;

  > div:first-of-type {
    flex-basis: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpointsMap.sm.min}px) {
    > div:first-of-type {
      flex-basis: 60%;
    }
  }
`;

const CouponWrapper = styled("div")`
  position: relative;
  display: inline-flex;

  picture,
  img {
    width: 100%;
    max-width: 180px;
    height: auto;
  }
`;

const CouponPrice = styled(Price)`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.8);
`;

export const Coupon = ({ price }) => (
  <CouponWrapper size="m">
    <Image src="/img/mm-neu/geschenk-coupon@2x.png" alt={"Geschenk Coupon"} />
    <CouponPrice forceCentDisplay price={price} variant="secondary" />
  </CouponWrapper>
);

const OfferTeaserEnergy = (props) => {
  const {
    state: { rootInfo },
  } = useOfferConfigurator();

  const {
    coupon,
    tariff,
    offerType,
    tariff: { bullets, name, serviceProvider },
  } = rootInfo;

  const { cartButtonQaName } = props;

  return (
    <>
      <Header
        headline={name}
        provider={serviceProvider}
        providerlogo={serviceProvider}
      />
      <ContentWrapper>
        <div>
          <Bulletlist list={bullets} variant="checkmark" />
          <DetailsLinks tariff={tariff} offerType={offerType} coupon={coupon} />
        </div>
        {coupon?.value > 0 && <Coupon price={coupon.value} />}
      </ContentWrapper>

      <Footer tariff={tariff} offerType={offerType} qaName={cartButtonQaName} />
    </>
  );
};

export default OfferTeaserEnergy;
