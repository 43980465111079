import React from "react";

import { Headline } from "@mm/ui";

import { tariffPropTypes } from "sharedPropTypes";

import { DetailsAccordion } from "components/atoms";
/**
 *
 * @param {string} serviceProvider
 * @param {string} carrier
 * @returns {string}
 */
const getPdf = (serviceProvider, carrier) => {
  if (serviceProvider.toLowerCase() === "klarmobil") {
    return "klarmobil_preise_und_leistungen.pdf";
  }

  switch (carrier.toLowerCase()) {
    case "telekom": {
      return "d1_preise_und_leistungen.pdf";
    }
    case "vodafone": {
      return "d2_preise_und_leistungen.pdf";
    }
    case "e-plus": {
      return "ep_preise_und_leistungen.pdf";
    }
    case "o2": {
      return "o2_preise_und_leistungen.pdf";
    }
    case "telefonica": {
      return "telefonica_preise_und_leistungen.pdf";
    }
    case "super select": {
      return "superselect_preisliste.pdf";
    }
    default: {
      return "no-carrier-found.pdf";
    }
  }
};
const TariffInfo = ({ tariff, carrier }) => {
  return (
    <>
      <Headline variant="h4" mb={4}>
        Tarifdetails
      </Headline>
      <DetailsAccordion details={tariff.details} />
      {tariff.addresses && (
        <>
          <div className="msd-manufacturer-addresses-headline">
            <span>
              <b>Hersteller</b>
            </span>
          </div>
          <div className="msd-manufacturer-addresses-wrapper">
            {tariff.addresses.map((address) => (
              <div key={address.companyName} className="address">
                <div>{address.companyName}</div>
                <div>
                  <a
                    href={`mailto:${address.mail}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {address.mail}
                  </a>
                </div>
                <div>
                  <a href={address.website} target="_blank" rel="noreferrer">
                    {address.website}
                  </a>
                </div>
                <div>
                  <span>{address.street}</span>{" "}
                  <span>{address.streetNumber}</span>
                </div>
                <div>
                  <span>{address.zip}</span> <span>{address.city}</span>
                </div>
                <div>
                  <span>{address.country}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {carrier && (
        <p className="prices-pdf">
          Die aktuellen Preise und Leistungen entnehmen Sie bitte der Übersicht{" "}
          <a
            href={`${window.CDN_URL}/pdf/${getPdf(
              tariff.serviceProvider,
              carrier
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            Preise und Leistungen
          </a>
          .
        </p>
      )}
    </>
  );
};

TariffInfo.propTypes = {
  tariff: tariffPropTypes,
};

TariffInfo.defaultProps = {
  tariff: {},
};

export default TariffInfo;
