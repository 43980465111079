import React from "react";

import { DetailsAccordion } from "components/atoms";

import "./style.scss";

const HardwareInfo = ({ hardware }) => {
  return (
    <div className="msd-hardware-info-overlay">
      <div>
        {hardware.manufacturer && (
          <span className="manufacturer">{hardware?.manufacturer} </span>
        )}
        {hardware.name && <span className="hardware">{hardware?.name}</span>}
      </div>
      <DetailsAccordion details={hardware?.details} />
      {hardware.addresses && (
        <>
          <div className="msd-manufacturer-addresses-headline">
            <span>
              <b>Hersteller</b>
            </span>
          </div>
          <div className="msd-manufacturer-addresses-wrapper">
            {hardware.addresses.map((address) => (
              <div key={address.companyName} className="address">
                <div>{address.companyName}</div>
                <div>
                  <a
                    href={`mailto:${address.mail}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {address.mail}
                  </a>
                </div>
                <div>
                  <a href={address.website} target="_blank" rel="noreferrer">
                    {address.website}
                  </a>
                </div>
                <div>
                  <span>{address.street}</span>{" "}
                  <span>{address.streetNumber}</span>
                </div>
                <div>
                  <span>{address.zip}</span> <span>{address.city}</span>
                </div>
                <div>
                  <span>{address.country}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default HardwareInfo;
